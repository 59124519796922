@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;400;500;600;700&display=swap');
@import url('https://fonts.cdnfonts.com/css/albertus-medium');
@import url('https://fonts.cdnfonts.com/css/albertus-mt-std');

:root {
    font-size: 60%;
    --color-background: #f3ead5;
    --color-background-dark: #574430;
    --color-text-title: #f3ead5;
    --color-text-title-shadow: #574430; 
    --color-text-base: #f3ead5;
    --color-text-base-dark: #574430;
    --color-text-hover: #382A1B;   
    --color-text-block: #574430;
    --color-brown: #574430;

    font-family: 'Albertus MT Std', 'Albertus Medium';
}

* {
    margin: 0;
    padding: 0;    
    box-sizing: border-box;
}

html, body, #root {
    height: 100vh;
}

body {
    background: var(--color-background-dark);   
}

#root {
    display: flex;
    align-items: center;
    justify-content: center;
}

body,
input,
button,
textarea {
    font: 500 1.6rem Montserrat;
    color: var(--color-text-base);
}

.container {
    width: 90vw;    
}

.footer-background {
    background: var(--color-text-hover);
}

@media (min-width: 700px) {
    :root {
        font-size: 62.5%;
    }
}