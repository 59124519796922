.group {
  height: 100rem;  
  background: var(--color-secundary);
  background-image: url('../../../assets/images/background.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.group .group-content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;  
}

.group .group-content .group-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 6rem;
}

.group .group-content .group-main strong {
  font: 600 2rem 'Albertus Medium';
  color: var(--color-text-base-dark);
  text-shadow: 1px 1px var(--color-text-title);  
}

.group .group-content .links {
  width: 100%;
  height: 28rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 2rem;
}

.group .group-content .text-box {
  width: 32rem;
  height: 6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(56, 42, 27, 0.8);
  border: 0;
  border-radius: 6rem;  
  text-align: center;  
}

.group .group-content .text-box strong {
  font: 600 1.7rem Montserrat;
  color: var(--color-text-base);  
}

.group .group-content .text-box p {
  font: 600 1.2rem Montserrat;
  color: var(--color-text-base);
  margin-top: 0.5rem;
}

.group .group-content .text-box a {
  text-decoration: none;
}

@media (min-width: 600px) {
  .group {
    height: 100rem;
  }
  
  .group .group-content .group-main strong {
    font: 600 3rem 'Albertus Medium';    
  }

  .group .group-content .links {
    height: 30rem;   
  }

  .group .group-content .text-box {
    width: 40rem;
    height: 6rem;   
  }

  .group .group-content .text-box strong {
    font: 600 2rem Montserrat;      
  }  
}

@media (min-width:1200px) {
  .group .group-content .group-main {
    margin-bottom: 6rem;
    margin-left: 40%;
  }
  
  .group .group-content .group-main strong {
    font: 600 4rem 'Albertus Medium';
    color: var(--color-text-base-dark);
    text-shadow: 2px 2px var(--color-text-title);
  }
  
  .group .group-content .links {
    height: 20rem;
    flex-direction: row;
    justify-content: center;
  }

  .group .group-content .text-box {
    width: 42rem;
    height: 7rem;
    margin-left: 5rem;
    margin-right: 5rem; 
  }

  .group .group-content .text-box strong {
    font: 600 2.2rem Montserrat;      
  }  
}

@media (min-width: 1600px) {
  .group .group-content .group-main {
    margin-bottom: 5.5rem;
    margin-left: 37%;
  }
  
  .group .group-content .group-main strong {
    font: 600 6rem 'Albertus Medium';
    color: var(--color-text-base-dark);    
  }
  
  .group .group-content .text-box {   
    margin-top: 0;
  }  
}