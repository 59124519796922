#contact-me-page {
	width: 100vw;
  height: 100vh;
  overflow-x: hidden; 
}

.contact-me {
  width: 100%;
  height: 80rem;  
  background-image: url('../../assets/images/background_form.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.contact-me .contact-me-content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;  
}

.contact-me .contact-me-content #loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.contact-me .contact-me-content .contact-me-main {
  width: 93%;
  height: 93%;  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-me .contact-me-content .contact-me-main .form-box {
  width: 90%;
  height: 90%;
  background: var(--color-background-dark); 
  border: 0;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
}

.contact-me .contact-me-content .contact-me-main .form-box img {
  width: 12rem;
  height: 12rem; 
}

.contact-me .contact-me-content .contact-me-main .form-box .contact-box {
  width: 20rem;
  height: 3.8rem;
  border-style: solid;
  border-radius: 5rem;
  background-color: var(--color-background);
  border-color: var(--color-background-dark);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.contact-me .contact-me-content .contact-me-main .form-box .contact-box strong {
  font: 700 2.2rem 'Albertus Medium';
  color: var(--color-text-base-dark);
  text-shadow: none;
  margin-top: 0;
}

.contact-me .contact-me-content .contact-me-main .form-box form {
  width: 90%;
  height: 65%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;  
}

.contact-me .contact-me-content .contact-me-main .form-box form p {
  margin-bottom: 0.5rem;
  color: var(--color-text-white);
}

.contact-me .contact-me-content .contact-me-main .form-box form .button-box {
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 2rem;
}

.contact-me .contact-me-content .contact-me-main .form-box form .button-box button {
  width: 12rem;
  height: 3rem;
  background: var(--color-background);
  color: var(--color-text-base-dark);
  border: 0;
  border-radius: 3rem;
  cursor: pointer;
  font: 600 1.5rem barlow;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  transition: 0.2s;   
}

.contact-me .contact-me-content .contact-me-main .form-box form .button-box button:hover {
  color: var(--color-text-hover);
}

.contact-me .contact-me-content .contact-me-main .form-box strong {
  font: 700 2.2rem 'Albertus Medium';
  color: var(--color-text);
  text-shadow: 1px 1px var(--colot-text-hover);
  margin-top: 4rem;
}

@media (min-width: 600px) {  
  .contact-me .contact-me-content .contact-me-main .form-box .contact-box {
    width: 20rem;
    height: 3.8rem;   
  }

  .contact-me .contact-me-content .contact-me-main .form-box {
    width: 50rem;
  }

  .contact-me .contact-me-content .contact-me-main .form-box strong {
    font: 700 2.5rem 'Albertus Medium';   
  }
}

@media (min-width: 1200px) {
  .contact-me {
    height: 80rem;
  }  
  
  .contact-me .contact-me-content .contact-me-main .form-box .contact-box {
    width: 27rem;
    height: 4.5rem;
    margin-top: 2rem;   
  }

  .contact-me .contact-me-content .contact-me-main .form-box .contact-box strong {
    font: 700 3rem barlow;    
  }

  .contact-me .contact-me-content .contact-me-main .form-box {
    width: 80rem;
  }
  .contact-me .contact-me-content .contact-me-main .form-box img {
    width: 13rem;
    height: 13rem; 
  }

  .contact-me .contact-me-content .contact-me-main .form-box strong {
    font: 700 3.5rem 'Albertus Medium';    
    margin-top: 2rem;
  }
}
