.footer {
  width: 100%;
  height: 40rem;  
}

.footer .footer-content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;  
}

.footer .footer-content .logo {
  width: 11rem;
  height: 11rem;
  margin-top: 5rem;
}

.footer .footer-content .logo img {
  width: 100%;
  height: 100%;
}

.footer .footer-content .midias:first-child {
  height: 0;
  margin-top: 0;
  margin-bottom: 0; 
}

.footer .footer-content .midias {
  width: 90%;
  height: 20rem;  
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
  margin-top: 1rem;
  margin-bottom: 2rem;  
}

.footer .footer-content .midias .midia {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.footer .footer-content .midias .midia img {
  width: 5rem;
  height: 4.27rem;
}

.footer .footer-content .midias .midia strong {
  font: 600 1.4rem Montserrat;
  color: var(--color-text-base);
  margin-top: 1rem;
}

.footer .footer-content .midias #termos {
  font: 600 1.5rem Montserrat;
  color: var(--color-text-base);
  text-shadow: 1px 1px var(--color-text-base-dark);
}

@media (min-width: 600px) {
  .footer .footer-content .midias {
    width: 90%;
    height: 20rem;    
    margin-top: 1rem;
    margin-bottom: 3rem;
  }
}

@media (min-width: 1200px) {
  .footer {
    height: 25rem;
  }
  
  .footer .footer-content {
    flex-direction: row;
    justify-content: center;
  }

  .footer .footer-content .logo {
    margin-bottom: 0;
    margin-top: 0;
  }

  .footer .footer-content .midias:first-child {
    margin-left: 0;
    margin-right: 5rem;
  }

  .footer .footer-content .midias {
    width: 50rem;
    height: 10rem;   
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 5rem;
    margin-right: 0;
  }

  .footer .footer-content .midias .midia {
    margin-bottom: 0;
  }
  
  .footer .footer-content .midias #termos {
    font: 600 2rem Montserrat;    
  }
}

@media (min-width: 1600px) {  
  .footer .footer-content .midias {
    width: 50rem;
  } 
}
