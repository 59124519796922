#privacy-page {
	width: 100vw;
  height: 100vh;
  overflow-x: hidden; 
}

.privacy {
  width: 100%;
  height: 220rem;  
  background-image: url('../../assets/images/background_form.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.privacy .privacy-content {
  width: 85%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: justify;
}

.privacy .privacy-content h1 {
  font: 700 2.2rem Montserrat;
  color: var(--color-text-base-dark);
}

.privacy .privacy-content p {
  font: 500 1.5rem Montserrat;
  color: var(--color-text-base-dark);
}

.privacy .privacy-content strong {
  font: 600 1.8rem Montserrat;
  color: var(--color-text-base-dark);
}

.privacy .privacy-content a {
  color: var(--color-text-base-dark);
}

@media (min-width: 600px) {
  .privacy .privacy-content {
    width: 60%;
  }
}

@media (min-width: 1200px) {
  .privacy {
    height: 180rem;
  }

  .privacy .privacy-content {
    width: 40%;
  }
}
