.ebook {
  height: 190rem;  
  background: var(--color-secundary);
  background-image: url('../../../assets/images/background_ebook.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.ebook .ebook-content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;  
}

.ebook .ebook-content .ebook-block {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ebook .ebook-content .ebook-story {
  width: 90%;
  height: 90rem;  
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.ebook .ebook-content .ebook-story h1 {
  font: 600 3rem 'Albertus Medium';
  color: var(--color-text-hover);
  text-decoration: underline;
}

.ebook .ebook-content .ebook-story strong {
  font: 600 2.5rem 'Albertus Medium';
  color: var(--color-text-hover);
  text-align: center;
}

.ebook .ebook-content #text-box {
  width: 30rem;
  height: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-background);
  border: 0;
  border-radius: 6rem;  
  text-align: center;  
}

.ebook .ebook-content #text-box strong {
  font: 600 1.7rem Montserrat;
  color: var(--color-text-hover);  
}

.ebook .ebook-content #text-box strong:hover {
  color: var(--color-text-block);
}

.ebook .ebook-content #text-box a {
  text-decoration-color: var(--color-text-hover);
}

.ebook .ebook-content #text-box-tablet {
  width: 30rem;
  height: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-background);
  border: 0;
  border-radius: 6rem;  
  text-align: center;  
}

.ebook .ebook-content #text-box-tablet strong {
  font: 600 1.7rem Montserrat;
  color: var(--color-text-hover);  
}

.ebook .ebook-content #text-box-tablet strong:hover {
  color: var(--color-text-block);
}

.ebook .ebook-content #text-box-tablet a {
  text-decoration-color: var(--color-text-hover);
}

.ebook .ebook-content .ebook-story .pergaminho {
  width: 100%;
  height: 60rem;
  background: var(--color-secundary);
  background-image: url('../../../assets/images/pergaminho.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ebook .ebook-content .ebook-story .pergaminho p {
  width: 90%;
  font: 500 1.5rem 'Albertus MT Std';
  color: var(--color-text-hover);
  text-align: justify;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.ebook .ebook-content .ebook-tablet {
  width: 90%;
  height: 55rem;  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ebook .ebook-content .ebook-tablet img {
  width: 30rem;
  height: 39rem;
}

.ebook .ebook-content .ebook-tablet .tablet-links {
  height: 12rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

@media (min-width: 600px) {
  .ebook .ebook-content .ebook-story .pergaminho p {
    width: 85%;
    font: 500 2rem albertus;    
  }
}

@media (min-width: 1200px) {
  .ebook {
    height: 120rem;  
  }
  
  .ebook .ebook-content .ebook-block {
    flex-direction: row;
    justify-content: space-evenly;
  }

  .ebook .ebook-content .ebook-story {
    width: 45%;    
    justify-content: flex-end;
  }

  .ebook .ebook-content .ebook-story h1 {
    margin-bottom: 3rem;
  }
  
  .ebook .ebook-content .ebook-story strong {
    margin-bottom: 3rem;
  }
  
  .ebook .ebook-content #text-box {
    margin-bottom: 0;
  }

  .ebook .ebook-content #text-box-tablet {
    margin-bottom: 0;
    width: 22rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .ebook .ebook-content .ebook-story .pergaminho {
    height: 63rem;
    margin-bottom: 2rem;
  }  

  .ebook .ebook-content .ebook-tablet {
    width: 45%;
    height: 90rem;    
    justify-content: flex-end;
  }

  .ebook .ebook-content .ebook-tablet img {
    width: 61.5rem;
    height: 80rem;
    margin-bottom: 1rem;
  }  

  .ebook .ebook-content .ebook-tablet .tablet-links {
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
  }  
}

@media (min-width: 1600px) {
  .ebook .ebook-content .ebook-block {
    justify-content: center;
  }

  .ebook .ebook-content .ebook-tablet {
    width: 35%;
    margin-left: 10rem;
  }

  .ebook .ebook-content #text-box {
    width: 35rem;
    height: 6rem;    
  }

  .ebook .ebook-content #text-box-tablet {
    width: 30rem;
    height: 6rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  
  .ebook .ebook-content #text-box strong {
    font: 600 2.2rem Montserrat;
    color: var(--color-text-hover);  
  }

  .ebook .ebook-content #text-box-tablet strong {
    font: 600 2.2rem Montserrat;
    color: var(--color-text-hover);  
  }
}